import { Injectable } from '@angular/core';
import {RestClientService} from '../core/services/api-access/rest-client.service';

@Injectable({
  providedIn: 'root'
})
export class ThumbnailRemoteService {
  private URL = '/projects';

  constructor(private restClientService: RestClientService) { }

  createVideoThumbnail(projectId) {
    return this.restClientService.get(this.URL + '/videos/' + projectId);
  }
}
