import {Injectable} from '@angular/core';
import {Region} from '../../../../models/region';
import {RestClientService} from '../../api-access/rest-client.service';
import {RegionSearchCriteria} from '../../../../models/region-search-criteria';
import {Observable} from 'rxjs';
import {Retrievable} from '../../../../shared/models/retrievable';
import {Searchable} from '../../../../shared/models/searchable';


@Injectable()
export class RegionRemoteService implements
  Searchable<Region, RegionSearchCriteria>,
  Retrievable<Region, number> {

  private URL = '/regions';

  constructor(private restClientService: RestClientService) { }

  public search(criteria?: RegionSearchCriteria): Observable<Region[]> {
    return this.restClientService.get(this.URL, criteria);
  }

  public get(id: number): Observable<Region> {
    return this.restClientService.get(`${this.URL}/${id}`);
  }
}
