import {Pipe, PipeTransform} from '@angular/core';
import {I18nService} from '../../core/services/i18n.service';

@Pipe({
    name: 'i18n',
})
export class I18nPipe implements PipeTransform {
    constructor(private i18nService: I18nService) {}

    public transform(value: any, keyParams?: object): string {
      return this.i18nService.getText(value, keyParams);
    }
}
