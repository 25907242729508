import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    public set(key: string, value: string): void {
      // window.localStorage[key] = value;
      localStorage.setItem(key, value);
    }

    public get(key: string, defaultValue?: string): string {
      return localStorage.getItem(key) || defaultValue;
      // return window.localStorage[key] || defaultValue;
    }

    public setObject(key: string, value: any): void {
      localStorage.setItem(key, JSON.stringify(value));
      // window.localStorage[key] = JSON.stringify(value);
    }

    public getObject(key: string): any {
      return JSON.parse(localStorage.getItem(key));
      // return JSON.parse(window.localStorage[key] || null);
    }

    public delete(key: string): any {
      localStorage.removeItem(key);
      // delete window.localStorage[key];
    }
}
