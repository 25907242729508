import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../api-access/rest-client.service';
import {RedsysCreateParams} from '../../../../shared/models/redsys/redsys-create-params';
import {RedsysResponse} from '../../../../shared/models/redsys/redsys-response';
import {RedsysSubscriptionCreateParams} from '../../../../shared/models/redsys/redsys-subscription-create-params';
import {RedsysSubscriptionResponse} from '../../../../shared/models/redsys/redsys-subscription-response';

@Injectable({
  providedIn: 'root'
})
export class RedsysRemoteService {

  private URL = 'redsys';

  constructor(private restClientService: RestClientService) { }

  createMerchantParameters(redsysCreateParams: RedsysCreateParams): Observable<RedsysResponse> {
    return this.restClientService.post(`${this.URL}/create-parameters`, redsysCreateParams);
  }

  checkMerchantParameters(redsysParam): Observable<string> {
    return this.restClientService.post(`${this.URL}/checkParameters`, redsysParam);
  }

  createSubscriptionMerchantParameters(redsysCreateParams: RedsysSubscriptionCreateParams): Observable<RedsysSubscriptionResponse> {
    return this.restClientService.post(`${this.URL}/create-subscription-parameters`, redsysCreateParams);
  }
}
