import {Injectable} from '@angular/core';
import {Retrievable} from '../../../../shared/models/retrievable';
import {Searchable} from '../../../../shared/models/searchable';
import {RestClientService} from '../../api-access/rest-client.service';
import {Observable} from 'rxjs';
import {Sector} from '../../../../shared/models/company/sector';
import {SectorSearchCriteria} from '../../../../shared/models/company/sector-search-criteria';


@Injectable({
  providedIn: 'root'
})
export class SectorRemoteService implements
Searchable<Sector, SectorSearchCriteria>,
Retrievable<Sector, number> {

  private URL = '/sectors';

  constructor(private restClientService: RestClientService) { }

  public search(criteria?: SectorSearchCriteria): Observable<Sector[]> {
    return this.restClientService.get(this.URL, criteria);
  }

  public get(id: number): Observable<Sector> {
    return this.restClientService.get(`${this.URL}/${id}`);
  }
}
