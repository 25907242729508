import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-ok',
  templateUrl: './payment-ko.component.html',
  styleUrls: ['./payment-ko.component.scss']
})
export class PaymentKoComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
  });
   }

  ngOnInit() {
  }

}
