import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../core/services/api-access/rest-client.service';
import { Information } from '../models/information';

@Injectable({
    providedIn: 'root'
})
export class InfoRemoteService {

    constructor(private restClientService: RestClientService) { }

    public get(name: string): Observable<Information> {
        return this.restClientService.get(`${name}`);
    }
}
