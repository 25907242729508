import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private loading = new BehaviorSubject<boolean>(false);

    public showLoader(): void {
      this.loading.next(true);
    }

    public hideLoader(): void {
      this.loading.next(false);
    }

    public isLoading(): Observable<boolean> {
      return this.loading;
    }
}
