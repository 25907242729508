import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'split',
})
export class SplitPipe implements PipeTransform {
    constructor() {}

    public transform(value: string, length?: number): string {
      if (value.length > length) {
        return value.slice(0, length) + '...';
      }
      return value;
    }
}
