import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() navbarBackgroundBlue = true;
  @Input() showBackground = true;
  @Input() lowHeader = false;
}
