import {Component, OnInit} from '@angular/core';
import {LoaderService} from '../../../core/services/loader.service';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  isLoading: boolean;
  windowHeight: number;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.isLoading().pipe(delay(0)).subscribe((isLoading) => {
      this.windowHeight = window.innerHeight;
      this.isLoading = isLoading;
    });
  }
}
